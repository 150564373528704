export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER FAIL";
export const LOGIN_SUCCESS = "LOGIN SUCCESS";
export const LOGIN_FAIL = "LOGIN FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const SET_LOADING = "SET_LOADING";
export const CLEAR_LOADING = "CLEAR_LOADING";

export const ADD_COUNT = "ADD_COUNT";
export const REMOVE_COUNT = "REMOVE_COUNT";

export const ADD_BRAND = "ADD_BRAND";
export const REMOVE_BRAND = "REMOVE_BRAND";