import React, { useState } from 'react';
// import InputHook from '../Input/InputHook';
import ButtonHook from '../Input/ButtonHook';
import CustomerService from '../../Services/CustomerService';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export default function RegisterTelephone({ setRegisterState, setTelephone, telephone, setGameRegistered }) {

    const { t } = useTranslation();
    const [btnTelephone, setBtnTelephone] = useState(false);
    const { brand } = useSelector((state) => state.brand);

    const checkTelephone = async () => {
        setBtnTelephone(true)
        if (telephone == null) {
            await Swal.fire(t("SwalNotification"), t("RegisterRequiredTelephone"), "warning");
            await setBtnTelephone(false)
            return
        }
        if (telephone.length < 10) {
            await Swal.fire(t("SwalNotification"), t("RegisterTelephoneLength"), "warning");
            await setBtnTelephone(false)
            return
        }
        const values = await {};
        values.telephone = await telephone;
        values.brand_id = await brand.id;
        await CustomerService.findByTelephone(values)
            .then(response => {
                setRegisterState(1);
                setGameRegistered(response.data.customer);
            })
            .catch(error => {
                console.log(error);
            });
        await setBtnTelephone(false);
    }

    return (
        <>
            <h1 className="card-title text-center">
                📞
            </h1>
            <div className="card-text">
                <h4 className="text-center mt-4">
                    {t("RegisterCheckTelephone")}
                </h4>

                <div className="row ">
                    <div className="col-lg-12">
                        <div>
                            <div class="form-icon right">
                                <input type="number" name="telephone" className="form-control form-control-icon" onChange={(e) => setTelephone(e.target.value)} />
                                <i class="ri-mail-unread-line"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <ButtonHook type="button" className="btn btn-primary w-100 btn-sm mt-2 mb-5" onClickHandler={() => checkTelephone()} isLoading={btnTelephone} value={`${t("Check")}`} />
                <small className="text-center img-center"></small>
            </div>
        </>
    )
}
