import React from 'react';
import { useTranslation } from 'react-i18next';

export default function RegisterSuccess({ customerResponse }) {

    const { t } = useTranslation();

    return (
        <>
            <h1 className="card-title text-center mb-4">
                ✅
            </h1>
            <div className="card-text">
                <h5 className="text-center">{t("RegisterSuccessHeader1")}</h5>
                <h5 className="text-center">CODE : {customerResponse.code}</h5>
                <p className="text-center">{t("RegisterSuccessP")}</p>
            </div>
        </>
    )
}
