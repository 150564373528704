import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import RegisterGameDetail from './RegisterGameDetail';
import { useTranslation } from 'react-i18next';

const RegisterGame = ({ brandCode, selectGame, setRegisterState, gameRegistered }) => {

    const { t } = useTranslation();
    const { brand } = useSelector((state) => state.brand);
    const params = useParams();

    return (
        <>
            <h1 className="card-title text-center mb-4">
                🎮
            </h1>
            <div className="card-text">
                <h4 className="text-center">{t("RegisterChosenGame")}</h4>
                <small className="text-center img-center">{t("RegisterGameCondition")}</small>
                <div className="row mt-5 mb-4">
                    {(brand.brand_games.map((game, k) => {
                        return <React.Fragment key={k}>
                            <RegisterGameDetail selectGame={selectGame} game={game} gameRegistered={gameRegistered} />
                        </React.Fragment>
                    }))}
                </div>
                <div className="row mt-2">
                    <div className="col-12">
                        <button className="btn btn-warning btn-block" onClick={() => setRegisterState(0)}>
                            <i className="fas fa-chevron-left"></i>&nbsp;&nbsp;
                            {t("Back")}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RegisterGame;
