import { CLEAR_LOADING, SET_LOADING } from '../Actions/type';

const initLoading = {
    loading: true,
};

export default function (state = initLoading, action) {
    const { type, payload } = action;
    switch (type) {
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            }
        case CLEAR_LOADING:
            return {
                ...state,
                loading: false,
            }
        default:
            return state;
    }
}
