import axios from "axios";
import config from "../config";

const API_URL = config.apiUrl() + "/api/customer";

class AuthService {

    // register = (name, username, password) => {
    //     return new Promise((resolve, reject) => {
    //         return axios.post(API_URL + "/login", {
    //             name: name,
    //             username: username,
    //             password: password
    //         }).then(response => {
    //             return resolve(response);
    //         }).catch(error => {
    //             return reject(error);
    //         })
    //     });
    // }

    login = (username, password) => {
        return new Promise((resolve, reject) => {
            return axios.post(API_URL + "/login", {
                username: username,
                password: password
            }).then(response => {
                if (response.data.customer) {
                    window.localStorage.setItem("customer", JSON.stringify({ user: response.data.customer, token: response.data.token }));
                }
                return resolve(response);
            }).catch(error => {
                return reject(error);
            })
        })
    }

    logout = () => {
        window.localStorage.removeItem("customer")
    }
}

export default new AuthService()