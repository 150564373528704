import { combineReducers } from 'redux'
import auth from './auth';
import loading from './loading';
import count from './count';
import brand from './brand';

export default combineReducers({
    auth,
    loading,
    count,
    brand
})