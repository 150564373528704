import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { useForm } from 'react-hook-form';
import { useSelector } from "react-redux";
import Config from '../config';
import Swal from 'sweetalert2';
import DashboardService from '../Services/DashboardService';
import ButtonHook from '../Components/Input/ButtonHook';
import { formatter, notifiaction } from '../Helpers';
import socketIOClient from 'socket.io-client';
import { useTranslation } from 'react-i18next';

const Withdraw = () => {

    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm();
    const { user } = useSelector((state) => state.auth);
    const { brand } = useSelector((state) => state.brand);
    const [amountLak, setAmountLak] = useState(0);
    const [btnLoading, setBtnLoading] = useState(false);
    const socket = socketIOClient("https://socket.casinoauto.app/");

    const fncWithdraw = async (values) => {
        setBtnLoading(true);
        document.querySelector(".currency-input").value = "";
        if (user.country === 1) {
            values.amount_transfer = parseFloat(values.amount.replaceAll(",", ""));
        } else if (user.country === 2) {
            values.amount_transfer = parseFloat(values.amount.replaceAll(",", "")) * brand.rate_laos;
        }
        values.amount = parseFloat(values.amount.replaceAll(",", ""));
        if (values.amount <= 0) {
            console.log(document.querySelector(".currency-input").value = "");
            await Swal.fire(t("SwalNotification"), t("WithdrawAmount0"), "warning");
            setBtnLoading(false);
            return
        }
        if (values.amount < brand.withdraw_min) {
            document.querySelector(".currency-input").value = "";
            await Swal.fire(t("SwalNotification"), t("WithdrawMin") + brand.withdraw_min, "warning");
            setBtnLoading(false);
            return
        }
        values.customer_id = await user.id;
        values.username = await user.username;
        values.type = 1;
        await DashboardService.withdrawCreate(values)
            .then(() => {
                notifiaction("พนักงานกำลังตรวจสอบรายการถอน", "info");
                socket.emit("create-withdraw", brand.id);

            }).catch(error => {
                Swal.fire(t("SwalNotification"), error.response.data.message, "warning");
            });

        setBtnLoading(false);
    }

    const convertToLak = (values) => {
        if (values == null) {
            setAmountLak(0);
            return
        }
        const amountLak = values * 500;
        setAmountLak(amountLak);
    }

    useEffect(() => {
        (async function () {
        })();
    }, []);

    return (
        <>
            <form onSubmit={handleSubmit(fncWithdraw)}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="content bg-danger text-white">
                            <h5 for=""> <i className="fad fa-credit-card"></i> {t("Withdraw")} </h5>
                        </div>
                        <div className="content bg-white">
                            <h5> <i className='fad fa-university'></i> {t("WithdrawHeader")}</h5>
                            <hr />
                            <p> <img src={`${Config.storageUrl()}/logo-banks/${user.bank.logo}`} alt={user.bank.name} width={50} /> {user.bank.name} {user.bank_account}</p>
                        </div>
                        <div className="content bg-warning">
                            <h5 for=""> <i class="fad fa-money-check-edit-alt"></i> {t("WithdrawAmountLabel")}</h5>
                            <hr className="br-black" />
                            <div className="">
                                <p><b>Username : </b>{user.username}</p>
                            </div>
                            <>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <CurrencyInput
                                            name="amount"
                                            field="amount"
                                            placeholder={t("WithdrawCreditRequired")}
                                            className={errors.amount ? "form-control is-invalid form-control currency-input" : "form-control currency-input invalid"}

                                            decimalsLimit={2}
                                            onValueChange={(value) => convertToLak(value)}
                                            {...register("amount", { required: true })} />
                                        {(user.country === 2)
                                            &&
                                            <>
                                                <small className="float-right">{t("WithdrawAmountToLak")} {formatter.format(amountLak)}</small>
                                            </>
                                        }
                                    </div>
                                </div>
                            </>
                            <small className="">{t("WithdrawMin")}<b> {brand.withdraw_min}</b> {t("Credit")}</small>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <ButtonHook isLoading={btnLoading} type="submit" className="btn btn-primary btn-lg w-100" value={t("Withdraw")}></ButtonHook>
                    </div>
                </div>
            </form>
        </>
    );
}

export default Withdraw;
