import axios from "axios";

const API_URL = "https://ap1.casinoauto.app/api/customer/dashboard";

class HistoryService {

    get = () => {
        return new Promise((resolve, reject) => {
            return axios.get(API_URL + "/history/get").then(response => {
                return resolve(response);
            }).catch(error => {
                return reject(error);
            })
        });
    }
}

export default new HistoryService()