import { ADD_COUNT, REMOVE_COUNT } from '../Actions/type';

const initLoading = {
    count: 0,
};

export default function (state = initLoading, action) {
    const { type, payload } = action;
    switch (type) {
        case ADD_COUNT:
            return {
                count: state.count + 1,
            }
        case REMOVE_COUNT:
            return {
                count: state.count - 1,
            }
        default:
            return state;
    }
}
