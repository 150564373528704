import axios from "axios";
import config from "../config";

const API_URL = config.apiUrl() + "/api/customer";

class ExampleService {

    find = (brand_id) => {
        return new Promise((resolve, reject) => {
            return axios.get(API_URL + "/brand/find/" + brand_id).then(response => {
                return resolve(response);
            }).catch(error => {
                return reject(error);
            })
        });
    }

    findByCode = (brand_code) => {
        return new Promise((resolve, reject) => {
            return axios.get(API_URL + "/brand/find-by-code/" + brand_code).then(response => {
                return resolve(response);
            }).catch(error => {
                return reject(error);
            })
        });
    }
}

export default new ExampleService()