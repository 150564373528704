import axios from "axios";
import config from "../config";

const API_URL = config.apiUrl() + "/api/customer";

class BankService {

    get = () => {
        return new Promise((resolve, reject) => {
            return axios.get(API_URL + "/bank/get").then(response => {
                return resolve(response);
            }).catch(error => {
                return reject(error);
            })
        });
    }
}

export default new BankService()