import React, { useEffect } from 'react'
import { Navigate, useOutlet, useParams } from 'react-router-dom'
import { useAuth } from '../Hooks/useAuth';
import { useSelector } from 'react-redux';
import BrandService from '../Services/BrandService';

export default function Authenticate() {

    const outlet = useOutlet();
    const params = useParams();
    const user = useSelector((state) => state.auth);

    if (user.isLoggedIn == true) {
        window.location.href = `/${params.brand}`;
    }

    return (
        <>
            <div className="container">
                {outlet}
            </div>
        </>
    )
}
