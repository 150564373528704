import axios from "axios";
import config from "../config";

const API_URL = config.apiUrl() + "/api/customer";

class BrandPromotionService {

    getByType = (values) => {
        return new Promise((resolve, reject) => {
            return axios.post(API_URL + "/brand-promotion/by-type", values).then(response => {
                return resolve(response);
            }).catch(error => {
                return reject(error);
            })
        });
    }

    get = () => {
        return new Promise((resolve, reject) => {
            return axios.get(API_URL + "/dashboard/promotion/get").then(response => {
                return resolve(response);
            }).catch(error => {
                return reject(error);
            })
        });
    }
}

export default new BrandPromotionService()