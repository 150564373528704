import React, { useEffect, useState } from 'react';
import HistoryService from "../Services/HistoryService";
import imgLoading from "../Assets/images/loading2.gif";
import { useTranslation } from 'react-i18next';
export const History = () => {

    const { t } = useTranslation();
    const [customerDeposits, setCustomerDeposits] = useState();
    const [customerWithdraws, setCustomerWithdraws] = useState();
    const [loading, setLoading] = useState(true);

    const getHistory = async () => {
        await HistoryService.get()
            .then(response => {
                setCustomerDeposits(response.data.customer_deposits);
                setCustomerWithdraws(response.data.customer_withdraws);
            }).catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        (async function () {
            await getHistory();
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        })();
    }, []);

    return (
        <>
            {loading ? (
                <>
                    <div className="loading-page">
                        <div className="loading-icon">
                            <img src={imgLoading} alt="" width={200} height={200} />
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="content bg-info">
                                <h5 className="text-white"> <i class="fad fa-history"></i> {t("HistoryDepositTransfer")}</h5>

                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='content bg-white'>
                                <h5 className='text-dark'>
                                    <i className='fad fa-dollar-sign'></i> {t("MenuDeposit")}
                                    <hr />
                                </h5>

                                <ul class="list-group">
                                    {(customerDeposits.map((deposit) => {
                                        return <>
                                            <li class="list-group-item list-group-item-success">
                                                <p className='mb-0'> {t("DateAndTime")} : {deposit.createdAt}</p>
                                                <p className='mb-0'>{t("AmountDeposit")} : {deposit.amount_transfer}</p>
                                            </li>
                                        </>
                                    }))}

                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='content bg-white'>
                                <h5 className='text-dark'>
                                    <i className='fad fa-dollar-sign'></i> {t("Withdraw")}
                                    <hr />
                                </h5>

                                <ul class="list-group">
                                    {(customerWithdraws.map((withdraw) => {
                                        return <>
                                            <li class="list-group-item list-group-item-warning">
                                                <p className='mb-0'> {t("DateAndTime")} :{withdraw.createdAt}</p>
                                                <p className='mb-0'>{t("AmountTransfer")} : {withdraw.amount_transfer}</p>
                                            </li>
                                        </>
                                    }))}

                                </ul>
                            </div>
                        </div>
                    </div>
                </>
            )
            }
        </>
    );
}