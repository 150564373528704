import { LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT, REGISTER_FAIL, REGISTER_SUCCESS } from '../Actions/type';

const customer = JSON.parse(window.localStorage.getItem("customer"));
const initUser = customer ? { isLoggedIn: true, user: customer.user, token: customer.token } : { isLoggedIn: false, user: null, token: null };

export default function (state = initUser, action) {

    const { type, payload } = action;

    switch (type) {
        case REGISTER_SUCCESS:
            return {
                ...state,
                isLoggedIn: true
            }
        case REGISTER_FAIL:
            return {
                ...state,
                isLoggedIn: false,

            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: payload.customer,
                token: payload.token
            }
        case LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null
            }
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                user: null
            }
        default:
            return state;
    }

}

