import React from 'react'

export default function ButtonHook({ type, value, className, isLoading, icon, onClickHandler }) {

    return (
        <React.Fragment>
            <button type={type} className={className} disabled={isLoading} onClick={onClickHandler}>
                {(isLoading)
                    ?
                    <React.Fragment>
                        <div class="spinner-border text-default mr-2" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        Loading...
                    </React.Fragment>
                    :
                    <span>
                        <i className={icon}></i>
                        {value}
                    </span>
                }
            </button >
        </React.Fragment >
    )
}
