import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../Actions/auth';
import { Link, useParams } from 'react-router-dom';
import InputHook from '../Components/Input/InputHook';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import laos from '../Assets/laos.png';
import th from '../Assets/thailand.png';
import config from '../config';

const Login = () => {

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const params = useParams();
    const { brand } = useSelector((state) => state.brand);

    const handleLogin = (values) => {
        dispatch(login(values.username, values.password))
            .then((response) => {
                console.log(response);
                // window.location.href = "/" + params.brand + "/";
            }).catch(error => {
                console.log(error);
                Swal.fire(t("SwalNotification"), error.response.data.message, "warning");
            });
    }

    return (
        <>
            <div className="container-auth mr-1 ml-1">
                <div className="card card-default card-login">
                    <div className="card-body">
                        <h3 className="card-title text-center">
                            <img src={`${config.storageUrl()}/logo-brands/${brand.logo}`} className="img-fluid img-logo-login" alt={brand.name} style={{ width: "200px !important" }} />
                        </h3>
                        <div className="card-text">
                            <form onSubmit={handleSubmit(handleLogin)} method="post">
                                {/* to error: add class "has-danger" */}
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1"> <i className="fad fa-user" /> {t("LoginHeader1")}</label>
                                    <InputHook type="text" field="username" errors={errors.username} register={register} errorLabel={{ requiredLabel: t("RequiredLabel") + t("Id") }} validates={{
                                        required: true,
                                    }} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1"> <i className="fad fa-key" />  {t("Password")}</label>
                                    <a href="#" className="pull-right">{t("ForgetPassword")} ?</a>
                                    <InputHook type="password" field="password" errors={errors.password} register={register} errorLabel={{ requiredLabel: t("RequiredLabel") + t("Password") }} validates={{
                                        required: true,
                                    }} />
                                </div>
                                <button type="submit" className="btn btn-warning btn-block">
                                    <i className="fad fa-sign-in" />&nbsp;&nbsp;{t("Login")}
                                </button>
                                <span className="d-block text-center mt-4 mb-4">🎉 {t("RegisterLabel1")} 🎉 </span>
                                <Link to={`/${params.brand}/register`} className="btn btn-success btn-block">
                                    <i className="fad fa-user-plus"></i>&nbsp;
                                    {t("Register")}
                                </Link>
                            </form>
                        </div>
                        <div className="clearfix"></div>
                        <div className="row justify-content-between mt-3">
                            <div className="col-4">
                                <a onClick={() => i18n.changeLanguage("th")} style={{ fontSize: "30px", cursor: "pointer" }}>
                                    <img src={th} width={20} alt="" />
                                </a>
                                &nbsp;
                                <a onClick={() => i18n.changeLanguage("laos")} style={{ fontSize: "30px", cursor: "pointer" }}>
                                    <img src={laos} width={20} alt="" />
                                </a>
                            </div>
                            <div className="col-8">
                                <div className="pull-right">
                                    {(brand.facebook !== "")
                                        &&
                                        <>
                                            <a href={brand.facebook} className="btn btn-primary btn-sm mt-2">
                                                <i className="fab fa-facebook" />
                                            </a>&nbsp;
                                        </>
                                    }
                                    {(brand.line !== "")
                                        &&
                                        <>
                                            <a href={brand.line} className="btn btn-success btn-sm mt-2">
                                                <i className="fab fa-line" />
                                            </a>&nbsp;
                                        </>
                                    }
                                    {(brand.whatsapp !== "")
                                        &&
                                        <>
                                            <a href={brand.whatsapp} className="btn btn-success btn-sm mt-2">
                                                <i className="fab fa-whatsapp" />
                                            </a>&nbsp;
                                        </>
                                    }
                                    {(brand.web !== "")
                                        &&
                                        <>
                                            <a href={brand.web} className="btn btn-info btn-sm mt-2">
                                                <i className="fab fa-internet-explorer" />
                                            </a>&nbsp;
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
