import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useOutlet, Navigate, useParams, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../Actions/auth';
import Footer from '../Components/Layout/Footer';
import imgLoading from '../Assets/images/loading2.gif';
import th from '../Assets/thailand.png';
import laos from '../Assets/laos.png';
import { useTranslation } from 'react-i18next';
import DashboardService from '../Services/DashboardService';
import config from '../config';

const Dashboard = () => {


    const { t, i18n } = useTranslation();
    const outlet = useOutlet();
    const auth = useSelector((state) => state.auth);
    const { brand } = useSelector((state) => state.brand);
    const [loading, setLoading] = useState(true);
    const params = useParams()
    const dispatch = useDispatch();
    const [language, setLanguage] = useState("th");

    if (auth.isLoggedIn === false) {
        window.location.href = "/" + params.brand + "/login";
    }

    const checkAuth = async () => {
        await DashboardService.checkAuth(auth.user.id)
            .then(() => {
            }).catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        (async () => {
            await checkAuth();
            await setLoading(false);
        })();
    }, []);

    const fncLogout = () => {
        dispatch(logout())
            .then(() => {
                window.location.href = "/" + params.brand + "/login";
            })
            .catch(error => {
                console.log(error);
            })
    }

    const fncChangeLanguage = (language) => {
        i18n.changeLanguage(language);
        setLanguage(language);
    }

    return (
        <>
            {(loading) ?
                <div className="loading-page">
                    <div className="loading-icon">
                        <img src={imgLoading} alt="" width={200} height={200} />
                    </div>
                </div>
                :
                <>
                    <div className="container-body">
                        <nav className="nav-header">
                            <div className="pull-right">
                                <div class="dropdown d-inline mt-3">
                                    <button className="btn mt-3 mr-1" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {(language === "th")
                                            ?
                                            <img src={th} width={25} alt="" />
                                            :
                                            <img src={laos} width={25} alt="" />
                                        }
                                    </button>
                                    <div class="dropdown-menu  dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                        <a class="dropdown-item icon" onClick={() => fncChangeLanguage("th")} style={{ cursor: "pointer" }}>
                                            <img src={th} width={20} alt="" />&nbsp;&nbsp;ไทย
                                        </a>
                                        <a class="dropdown-item icon" onClick={() => fncChangeLanguage("laos")} style={{ cursor: "pointer" }}>
                                            <img src={laos} width={20} alt="" />&nbsp;&nbsp;ລາວ
                                        </a>
                                    </div>
                                </div>
                                <div class="dropdown d-inline mt-3">
                                    <button className="btn mt-3 mr-1" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fad fa-user text-white"></i>
                                    </button>
                                    <div class="dropdown-menu  dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                        <Link to={`/${brand.code}/history`} class="dropdown-item" > <i className="fad fa-history"></i> {t("HistoryDepositTransfer")}</Link>
                                        <Link to={`/${brand.code}/profile`} class="dropdown-item" ><i className="fad fa-user"></i> {t("ProfileHeading")}</Link>
                                        <button class="dropdown-item" onClick={fncLogout}> <i className="fad fa-sign-out"></i> {t("Exit")}</button>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-container">
                                <img src={`${config.storageUrl()}/logo-brands/` + brand.logo} alt={brand.logo} className="img-logo" width={100} />
                                <h5>{brand.name}</h5>
                            </div>
                        </nav>
                        <div className="container" style={{ paddingBottom: "150px" }}>
                            <div className='row'>
                                <div className='col-lg-2'></div>
                                <div className='col-lg-8'>
                                    {outlet}</div>
                                <div className='col-lg-2'></div>
                            </div>
                            <div >
                            </div>

                        </div>
                        <Footer />
                    </div>
                </>
            }
        </>
    );
}

export default Dashboard;
