import axios from "axios";

const API_URL = "https://ap1.casinoauto.app/api/customer/dashboard";

class DashboardService {
	checkAuth = (customer_id) => {
		return new Promise((resolve, reject) => {
			return axios
				.get(API_URL + "/auth/" + customer_id)
				.then((response) => {
					return resolve(response);
				})
				.catch((error) => {
					return reject(error);
				});
		});
	};

	entrance = (brand_id, customer_id) => {
		return new Promise((resolve, reject) => {
			return axios
				.post(API_URL + "/entrance", {
					brand_id: brand_id,
					customer_id: customer_id,
				})
				.then((response) => {
					return resolve(response);
				})
				.catch((error) => {
					return reject(error);
				});
		});
	};

	withdrawCreate = (values) => {
		return new Promise((resolve, reject) => {
			return axios
				.post(API_URL + "/withdraw/web/store", values)
				.then((response) => {
					return resolve(response);
				})
				.catch((error) => {
					return reject(error);
				});
		});
	};

	depositCreate = (values) => {
		return new Promise((resolve, reject) => {
			return axios
				.post(API_URL + "/deposit/web/store", values)
				.then((response) => {
					return resolve(response);
				})
				.catch((error) => {
					return reject(error);
				});
		});
	};
}

export default new DashboardService();
