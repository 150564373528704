import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BrandPromotionService from "../../Services/BrandPromotionService";
import ButtonHook from "../Input/ButtonHook";
import { toast } from "react-toastify";
import config from "../../config";
import { useTranslation } from "react-i18next";

const RegisterTransfer = ({
	register,
	errors,
	setRegisterState,
	gameId,
	selectFile,
	btnRegister,
}) => {
	const { t } = useTranslation();
	const { brand } = useSelector((state) => state.brand);
	const [loading, setLoading] = useState(true);
	const [promotions, setPromotions] = useState();

	const getPromotionByType = async () => {
		const data = await {};
		data.brand_id = await brand.id;
		data.game_id = await gameId;
		data.type_promotion = await 1;
		await BrandPromotionService.getByType(data)
			.then((response) => {
				setPromotions(response.data.brand_promotions);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const copyToClipboard = (text) => {
		var textField = document.createElement("textarea");
		textField.innerText = text;
		document.body.appendChild(textField);
		textField.select();
		document.execCommand("copy");
		textField.remove();
		toast.info(`คัดลอกเรียบร้อยแล้ว ${text}`, {
			position: "top-center",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "dark",
		});
	};

	useEffect(() => {
		(async function () {
			await getPromotionByType();
			await setLoading(false);
		})();
	}, []);

	return (
		<>
			{!loading && (
				<>
					<h1 className="card-title text-center">💳</h1>
					<div className="card-text">
						<h4 className="text-center">{t("RegisterTransferHeader")}</h4>
						<p>{t("RegisterTransferP1")}</p>
						{brand.brand_bank_accounts
							.filter((bank_account) => bank_account.status_web === 1)
							.map((v, k) => {
								return (
									<>
										<div
											className="alert alert-secondary"
											role="alert"
											style={{
												backgroundColor: v.bank.background_color,
												color: v.bank.font_color,
											}}
										>
											<img
												src={`${config.storageUrl()}/logo-banks/${v.bank.logo}`}
												width={20}
												alt=""
											/>
											&nbsp;
											<span>{v.bank.name}</span>
											<div className="mt-1">
												{v.account} {v.name}
											</div>
											<div className="pull-right">
												<button
													type="button"
													className="btn btn-primary btn-sm"
													onClick={(e) => copyToClipboard(v.account)}
												>
													<i className="fad fa-copy"></i> {t("Copy")}
												</button>
											</div>
										</div>
									</>
								);
							})}
						<div className="clearfix"></div>
						<div className="row mt-2">
							<div className="col-lg-12">
								<label for="">{t("Promotion")}</label>
								{promotions.map((v, k) => {
									return (
										<>
											<div class="form-check form-radio-primary mb-1">
												<input
													class="form-check-input"
													type="radio"
													name="brand_promotion_id"
													{...register("brand_promotion_id")}
													id={`brand_promotion_id_${k}`}
													defaultChecked={v.id === 1}
													value={v.id}
												/>
												<label
													class="form-check-label"
													for={`brand_promotion_id_${k}`}
												>
													{v.name}
												</label>
											</div>
										</>
									);
								})}
							</div>
							<div className="col-lg-12">
								<label for="">{t("UploadSlip")}</label>
								<input
									type="file"
									field="img"
									name="img"
									onChange={(e) => selectFile(e)}
									className="form-control"
								/>
							</div>
						</div>
						<div className="row mt-2">
							<div className="col-6">
								<button
									className="btn btn-warning btn-block"
									onClick={() => setRegisterState(2)}
								>
									<i className="fas fa-chevron-left"></i>&nbsp;&nbsp;
									{t("EditData")}
								</button>
							</div>
							<div className="col-6">
								<ButtonHook
									isLoading={btnRegister}
									className="btn btn-success btn-block"
									onClick={() => setRegisterState(3)}
									value={t("Register")}
								/>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default RegisterTransfer;
