import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { copyToClipboard } from '../Helpers';
import { useTranslation } from 'react-i18next';

export const Profile = () => {

    const { t, i18n } = useTranslation();
    const { user } = useSelector((state) => state.auth);
    const { brand } = useSelector((state) => state.brand);

    const getScore = async () => {
    }
    useEffect(() => {
        (async function () {
            await getScore();
        })();
    }, []);

    return (
        <>
            <div className="row">
                <div className="col-lg-12 ">
                    <div className="content">
                        <h5 className="text-white"> <i class="far fa-clipboard-user"></i> {t("ProfileHeading")}</h5>
                        <hr />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className='col-lg-12'>

                    <div className="content bg-white">
                        <h5>{t("ProfileCheckBankAccount")}</h5>
                        <hr />
                        <p> <img src={`https://ap1.casinoauto.app/storage/logo-banks/${user.bank.logo}`} alt={user.bank.name} width={50} /> {user.bank.name} {user.bank_account}</p>
                        <p>{t("ProfileBankAccountName")} : {user.name}</p>
                    </div>
                </div>
                <div className="col-lg-12 ">
                    <div className="content text-white bg-dark">
                        <h5>
                            {" "}
                            <i className="fad fa-gamepad"></i> {t("ProfileGameLogin")}
                        </h5>
                        <hr />
                        <p>
                            <b>Username : </b>
                            {user.username}{" "}
                            <button
                                className="btn btn-info btn-sm pull-right"
                                onClick={() => copyToClipboard(user.username)}
                            >
                                <i className="fad fa-copy"></i> {t("Copy")}
                            </button>
                        </p>
                        <p>
                            <b>Username : </b>
                            {user.password_generate}{" "}
                            <button
                                className="btn btn-info btn-sm pull-right"
                                onClick={() => copyToClipboard(user.password_generate)}
                            >
                                <i className="fad fa-copy"></i> {t("Copy")}
                            </button>
                        </p>
                        <div className="row">
                            <div className="col-12">
                                <button type="button" className="btn btn-primary btn-sm w-100">
                                    {" "}
                                    <i className="fa fa-play"></i> &nbsp; &nbsp;{t("ProfileGameLogin")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 pull-right">
                    <div
                        class="btn-group pull-right"
                        role="group"
                        aria-label="Basic example"
                    >
                        {brand.facebook !== "" && (
                            <>
                                <a
                                    target="_blank"
                                    href={brand.facebook}
                                    className="btn btn-primary"
                                    style={{ textAlign: "left", fontSize: "12.25px" }}
                                    rel="noreferrer"
                                >
                                    <i className="fab fa-facebook" /> facebook
                                </a>
                                &nbsp;
                            </>
                        )}
                        {brand.line !== "" && (
                            <>
                                <a
                                    target="_blank"
                                    href={brand.line}
                                    className="btn btn-success"
                                    style={{ textAlign: "left", fontSize: "12.25px" }}
                                    rel="noreferrer"
                                >
                                    <i className="fab fa-line" /> line
                                </a>
                                &nbsp;
                            </>
                        )}
                        {brand.whatsapp !== "" && (
                            <>
                                <a
                                    target="_blank"
                                    href={brand.whatsapp}
                                    className="btn btn-success"
                                    style={{ textAlign: "left", fontSize: "12.25px" }}
                                    rel="noreferrer"
                                >
                                    <i className="fab fa-whatsapp" /> whatsapp
                                </a>
                                &nbsp;
                            </>
                        )}
                        {brand.web !== "" && (
                            <>
                                <a
                                    target="_blank"
                                    href={brand.web}
                                    className="btn btn-info"
                                    style={{ textAlign: "left", fontSize: "12.25px" }}
                                    rel="noreferrer"
                                >
                                    <i className="fab fa-internet-explorer" /> Website
                                </a>
                                &nbsp;
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}