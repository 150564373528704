import axios from 'axios';

export const AxiosInterceptor = (store, history) => {
    const customer = JSON.parse(window.localStorage.getItem("customer"));
    if (customer) {
        axios.defaults.headers.common['x-access-token'] = customer.token;
    }
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.interceptors.response.use(response => {
        return Promise.resolve(response);
    }, error => {
        if (error.response.status === 401) {
            window.localStorage.removeItem("customer");
            window.location.href = "/"
        }
        return Promise.reject(error);
    });
} 