import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../Actions/auth";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DashboardService from "../../Services/DashboardService";

export default function Footer() {
	const auth = useSelector((state) => state.auth);
	const { brand } = useSelector((state) => state.brand);
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const params = useParams();
	const [url, setUrl] = useState(null);

	const fncLogout = () => {
		dispatch(logout())
			.then((response) => {
				window.location.href = "/" + params.brand + "/login";
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getEntranceUrl = async () => {
		await DashboardService.entrance(brand.id, auth.user.id)
			.then((response) => {
				console.log(response);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	useEffect(() => {
		(async () => {
			await getEntranceUrl();
		})();
	}, []);

	return (
		<>
			<div className="navbar">
				<NavLink
					to={`/${params.brand}/deposit`}
					className={({ isActive }) => (isActive ? "active" : "")}
				>
					<i className="fad fa-hand-holding-usd" />
					<span className="d-block">{`${t("MenuDeposit")}`}</span>
				</NavLink>
				<NavLink
					to={`/${params.brand}/promotion`}
					className={({ isActive }) => (isActive ? "active" : "")}
				>
					<i className="fad fa-tags" />
					<span className="d-block">{t("Promotion")}</span>
				</NavLink>
				<NavLink to={`/${params.brand}`} className="plus">
					<i className="fad fa-home" />
				</NavLink>
				<NavLink
					to={`/${params.brand}/withdraw`}
					className={({ isActive }) => (isActive ? "active" : "")}
				>
					<i className="fad fa-credit-card" />
					<span className="d-block">{t("Withdraw")}</span>
				</NavLink>
				<a href="#" onClick={() => fncLogout()}>
					<i className="fad fa-sign-out" />
					<span className="d-block">{t("Exit")}</span>
				</a>
			</div>
		</>
	);
}
