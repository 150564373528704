import React, { useEffect, useState } from "react";
import DashboardService from "../Services/DashboardService";
import { useSelector } from "react-redux";
import imgLoading from "../Assets/images/loading2.gif";
import { copyToClipboard, notifiaction } from "../Helpers";
import config from "../config";
import { useForm } from "react-hook-form";
import InputHook from "../Components/Input/InputHook";
import ButtonHook from "../Components/Input/ButtonHook";
import BrandPromotionService from "../Services/BrandPromotionService";
import Swal from "sweetalert2";
import socketIOClient from "socket.io-client";
import { useTranslation } from "react-i18next";

const Deposit = () => {
	const { t } = useTranslation();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();
	const [loading, setLoading] = useState(true);
	const { brand } = useSelector((state) => state.brand);
	const { user } = useSelector((state) => state.auth);
	const [promotions, setPromotions] = useState();
	const [file, setFile] = useState();
	const [btnLoading, setBtnLoading] = useState(false);
	const socket = socketIOClient("https://socket.casinoauto.app/");

	const selectFile = (e) => {
		setFile(e.target.files[0]);
	};

	const getPromotionByType = async () => {
		const data = await {};
		data.brand_id = await user.brand_id;
		data.game_id = await user.game_id;
		data.customer_id = await user.id;
		data.type_promotion = await 2;
		await BrandPromotionService.getByType(data)
			.then((response) => {
				setPromotions(response.data.brand_promotions);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const submitDeposit = async (values) => {
		setBtnLoading(true);
		const formData = new FormData();

		formData.append("file", file);
		formData.append("customer_id", user.id);
		formData.append("username", user.username);
		formData.append("brand_promotion_id", parseInt(values.brand_promotion_id));
		await DashboardService.depositCreate(formData)
			.then(() => {
				reset();
				notifiaction("กรุณารอพนักงานตรวจสอบซักครู่นะคะ", "success");
				socket.emit("create-deposit", user.brand_id);
			})
			.catch((error) => {
				Swal.fire(
					t("SwalNotification"),
					error.response.data.message,
					"warning"
				);
			});
		setBtnLoading(false);
	};

	useEffect(() => {
		(async function () {
			await getPromotionByType();
			setTimeout(() => {
				setLoading(false);
			}, 1000);
		})();
	}, []);

	return (
		<>
			{loading ? (
				<>
					<div className="loading-page">
						<div className="loading-icon">
							<img src={imgLoading} alt="" width={200} height={200} />
						</div>
					</div>
				</>
			) : (
				<>
					<form onSubmit={handleSubmit(submitDeposit)} method="post">
						<div className="content bg-success text-white">
							<h5 className="">
								{" "}
								<i className="fad fa-hand-holding-usd"></i>&nbsp;
								{t("MenuDeposit")}
							</h5>
						</div>
						<div className="content text-dark bg-white">
							<h5 className="">
								{" "}
								<i className="fad fa-university"></i>&nbsp;
								{t("BankAccountTransfer")}
							</h5>
							<hr />
							{brand.brand_bank_accounts
								.filter((bank_account) => bank_account.status_web === 1)
								.map((v, k) => {
									return (
										<>
											<div
												className="alert alert-secondary"
												role="alert"
												style={{
													backgroundColor: v.bank.background_color,
													color: v.bank.font_color,
												}}
											>
												<img
													src={`${config.storageUrl()}/logo-banks/${
														v.bank.logo
													}`}
													width={20}
													alt=""
												/>
												&nbsp;
												<span>{v.bank.name}</span>
												<div className="mt-1">
													{v.account} {v.name}
												</div>
												<div className="pull-right">
													<button
														type="button"
														className="btn btn-primary btn-sm"
														onClick={(e) => copyToClipboard(v.account)}
													>
														<i className="fad fa-copy"></i> {t("Copy")}
													</button>
												</div>
											</div>
										</>
									);
								})}
						</div>
						<div className="content text-dark bg-warning">
							<h5 className="">
								{" "}
								<i className="fad fa-gift"></i>&nbsp;{t("Promotion")}
							</h5>
							<hr className="br-black" />
							{promotions.map((v, k) => {
								return (
									<>
										<div class="form-check form-radio-primary mb-1">
											<input
												class="form-check-input"
												type="radio"
												{...register("brand_promotion_id")}
												id={`brand_promotion_id_${k}`}
												defaultChecked={v.id === 1}
												value={v.id}
											/>
											<label
												class="form-check-label"
												for={`brand_promotion_id_${k}`}
											>
												{v.name}
											</label>
										</div>
									</>
								);
							})}
						</div>
						<div className="content text-white bg-info pb-4">
							<h5 className="">
								{" "}
								<i class="far fa-file-invoice-dollar"></i>&nbsp;
								{t("UploadSlip")}
							</h5>
							<hr />
							<input
								type="file"
								field="img"
								name="img"
								register={register}
								errors={errors.img}
								onChange={(e) => selectFile(e)}
								className="form-control"
								validates={{
									required: true,
								}}
								errorLabel={{
									requiredLabel: t("Please") + t("UploadSlip"),
								}}
							/>
						</div>
						<div className="row">
							<div className="col-lg-12">
								<ButtonHook
									type="submit"
									isLoading={btnLoading}
									className="btn btn-primary btn-lg w-100"
									value={t("DepositInform")}
								></ButtonHook>
							</div>
						</div>
					</form>
				</>
			)}
		</>
	);
};

export default Deposit;
