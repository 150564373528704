import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { copyToClipboard } from "../Helpers";
import { useTranslation } from "react-i18next";
import CustomerService from "../Services/CustomerService";
import Loading from "../Components/Loading";
import BrandPromotionService from "../Services/BrandPromotionService";
import Swal from "sweetalert2";
import socketIOClient from "socket.io-client";
import config from "../config";
import DashboardService from "../Services/DashboardService";

const Home = () => {
	const { t } = useTranslation();
	const auth = useSelector((state) => state.auth);
	const { brand } = useSelector((state) => state.brand);
	const [loading, setLoading] = useState(true);
	const [score, setScore] = useState(0);
	const [promotions, setPromotions] = useState([]);
	const [btnScore, setBtnScore] = useState(false);
	const [url, setUrl] = useState(null);

	const getCustomerScore = async () => {
		await CustomerService.getScore()
			.then(async (response) => {
				let total_score = 0;
				await response.data.customer_point.forEach((customer_point) => {
					total_score += parseFloat(customer_point.point);
				});
				if (total_score === 0) {
					setScore(response.data.customer.point);
				} else {
					setScore(total_score);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const receiveScore = async () => {
		const socket = await socketIOClient("https://socket.casinoauto.app/");
		setBtnScore(true);
		await CustomerService.receiveScore(score)
			.then(() => {
				getCustomerScore();
				socket.emit("create-credit-free", brand.id);
				Swal.fire("warning", "รับโบนัสสำเร็จเรีัยบร้อย", "success");
				setScore(0);
			})
			.catch((error) => {
				Swal.fire("warning", error.response.data.message, "warning");
			});
		setBtnScore(false);
	};

	const getPromotion = async () => {
		await BrandPromotionService.get()
			.then((response) => {
				setPromotions(response.data.promotions);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getEntranceUrl = async () => {
		await DashboardService.entrance(brand.id, auth.user.id)
			.then((response) => {
				console.log(response);
				setUrl(response.data.url);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	useEffect(() => {
		(async () => {
			await getCustomerScore();
			await getPromotion();
			await getEntranceUrl();
			setLoading(false);
		})();
	}, []);

	return (
		<>
			{loading ? (
				<>
					<Loading />
				</>
			) : (
				<>
					<div className="container mb-4 mt-5">
						<div className="circle-wrap">
							<button
								className="btn btn-circle"
								onClick={receiveScore}
								disabled={btnScore}
							>
								{btnScore ? (
									<>
										<div class="spinner-border" role="status">
											<span class="sr-only">Loading...</span>
										</div>
									</>
								) : (
									<>
										<i className="fa fa-hand-holding-usd" />
									</>
								)}
							</button>
							<span className="title-circle text-dark">{t("Score")}</span>
							<div className="inside-circle">$ {score}</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12 ">
							<div className="content text-white bg-dark">
								<h5>
									{" "}
									<i className="fad fa-gamepad"></i> {t("ProfileGameLogin")}
								</h5>
								<hr />
								<p>
									<b>Username : </b>
									{auth.user.username}{" "}
									<button
										className="btn btn-info btn-sm pull-right"
										onClick={() => copyToClipboard(auth.user.username)}
									>
										<i className="fad fa-copy"></i> {t("Copy")}
									</button>
								</p>
								<p></p>
								<b>Password : </b>
								{auth.user.password_generate}{" "}
								<button
									className="btn btn-info btn-sm pull-right"
									onClick={() => copyToClipboard(auth.user.password_generate)}
								>
									<i className="fad fa-copy"></i> {t("Copy")}
								</button>
								<p></p>
								<div className="row">
									<div className="col-12">
										<a
											href={url}
											target="_blank"
											className="btn btn-primary btn-sm w-100"
											rel="noreferrer"
										>
											{" "}
											<i className="fa fa-play"></i> &nbsp; &nbsp;
											{t("ProfileGameLogin")}
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12 pull-right">
							<div
								class="btn-group pull-right"
								role="group"
								aria-label="Basic example"
							>
								{brand.facebook !== "" && (
									<>
										<a
											target="_blank"
											href={brand.facebook}
											className="btn btn-primary"
											style={{ textAlign: "left", fontSize: "12.25px" }}
											rel="noreferrer"
										>
											<i className="fab fa-facebook" /> facebook
										</a>
										&nbsp;
									</>
								)}
								{brand.line !== "" && (
									<>
										<a
											target="_blank"
											href={brand.line}
											className="btn btn-success"
											style={{ textAlign: "left", fontSize: "12.25px" }}
											rel="noreferrer"
										>
											<i className="fab fa-line" /> line
										</a>
										&nbsp;
									</>
								)}
								{brand.whatsapp !== "" && (
									<>
										<a
											target="_blank"
											href={brand.whatsapp}
											className="btn btn-success"
											style={{ textAlign: "left", fontSize: "12.25px" }}
											rel="noreferrer"
										>
											<i className="fab fa-whatsapp" /> whatsapp
										</a>
										&nbsp;
									</>
								)}
								{brand.web !== "" && (
									<>
										<a
											target="_blank"
											href={brand.web}
											className="btn btn-info"
											style={{ textAlign: "left", fontSize: "12.25px" }}
											rel="noreferrer"
										>
											<i className="fab fa-internet-explorer" /> Website
										</a>
										&nbsp;
									</>
								)}
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12 ">
							<div className="content bg-warning text-dark">
								<h5 className="">
									<i class="fad fa-megaphone"></i> {t("Annoucement")}
								</h5>
								<hr className="br-black" />
								<p className="">...</p>
							</div>
						</div>
						<div className="col-lg-12 ">
							<div className="content">
								<h5 className="text-white">
									<i class="fad fa-gift"></i> {t("Promotion")}
								</h5>
								<hr />
								{promotions
									.filter(
										(promotion) =>
											promotion.status_web !== 1 && promotion.type !== 0
									)
									.map((promotion) => {
										return (
											<div className="col-lg-6">
												{promotion.image !== "" ? (
													<>
														<img
															src={`${config.storageUrl()}/promotions/${
																promotion.image
															}`}
															alt=""
															className="img-fluid"
														/>
													</>
												) : (
													<>
														<img
															src="https://via.placeholder.com/1050"
															className="img-fluid"
															alt=""
														/>
													</>
												)}
												<p className="pb-0 text-white">{promotion.name}</p>
											</div>
										);
									})}
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default Home;
