import { ADD_BRAND, REMOVE_BRAND } from '../Actions/type';

const initLoading = {};

export default function (state = initLoading, action) {
    const { type, payload } = action;
    switch (type) {
        case ADD_BRAND:
            return {
                brand: payload
            }
        case REMOVE_BRAND:
            return {
                brand: payload
            }
        default:
            return state;
    }
} 