import { setErrorMessage } from '../Helpers';
import AuthService from '../Services/AuthService';
import { REGISTER_SUCCESS, REGISTER_FAIL, SET_MESSAGE, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT } from './type';

export const register = (name, username, password) => (dispatch) => {
    // dispatch({
    //     type: REGISTER_SUCCESS,
    //     payload: {
    //         name: name,
    //         username: username,
    //         password: password
    //     }
    // });
    // return Promise.resolve();
    // return AuthService.register(name, username, password)
    //     .then(response => {
    //         dispatch({
    //             type: REGISTER_SUCCESS
    //         });
    //         return Promise.resolve();
    //     })
    //     .catch(error => {
    //         const message = setErrorMessage(error);
    //         dispatch({
    //             type: REGISTER_FAIL,
    //         });
    //         dispatch({
    //             type: SET_MESSAGE,
    //             payload: message,
    //         });
    //         return Promise.reject();
    //     })
}

export const login = (username, password) => (dispatch) => {
    return AuthService.login(username, password).then(
        (response) => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: response.data,
            });
            return Promise.resolve();
        },
        (error) => {
            const message = setErrorMessage(error);
            dispatch({
                type: LOGIN_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject(error);
        }
    );
};

export const logout = () => (dispatch) => {
    AuthService.logout();
    dispatch({
        type: LOGOUT,
    });
};