class Config {
	APP_ENV = process.env.REACT_APP_ENV;
	APP_SECRET = process.env.REACT_APP_SECRET;
	apiUrl = () => {
		// if (this.APP_ENV === "developer") {
		//     return "http://localhost:8000";
		// } else {
		return "https://ap1.casinoauto.app";
		// }
	};
	storageUrl = () => {
		if (this.APP_ENV === "developer") {
			return "https://ap1.casinoauto.app/storage";
		} else {
			return "https://ap1.casinoauto.app/storage";
		}
	};
}

export default new Config();
