import React, { useState } from "react";
import { useForm } from "react-hook-form";
import InputHook from "../Components/Input/InputHook";
import RegisterTelephone from "../Components/Register/RegisterTelephone";
import RegisterGame from "../Components/Register/RegisterGame";
import RegisterInformation from "../Components/Register/RegisterInformation";
import RegisterTransfer from "../Components/Register/RegisterTransfer";
import RegisterSuccess from "../Components/Register/RegisterSuccess";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import laos from "../Assets/laos.png";
import th from "../Assets/thailand.png";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import CustomerService from "../Services/CustomerService";
import socketIOClient from "socket.io-client";

export default function Register() {
	const { t, i18n } = useTranslation();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const params = useParams();
	const [gameId, setGameId] = useState();
	const [brandGameId, setBrandGameId] = useState();
	const [registerState, setRegisterState] = useState(0);
	const [bank, setBank] = useState(0);
	const [file, setFile] = useState(null);
	const { brand } = useSelector((state) => state.brand);
	const [btnRegister, setBtnRegister] = useState(false);
	const [telephone, setTelephone] = useState();
	const [gameRegistered, setGameRegistered] = useState();
	const [customerResponse, setCustomerResponse] = useState();
	const socket = socketIOClient("https://socket.casinoauto.app/");

	const selectGame = (game_id, brand_game_id) => {
		setGameId(game_id);
		setBrandGameId(brand_game_id);
		setRegisterState(2);
	};

	const selectFile = (e) => {
		setFile(e.target.files[0]);
	};

	const selectBank = (bank_id) => {
		setBank(bank_id);
	};

	const checkPassword = (str) => {
		var re =
			/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.* )(?=.*[^a-zA-Z0-9]).{8,16}$/m;
		return re.test(str);
	};

	const handleRegister = (values) => {
		// if (registerState == 0) {
		//     console.log("test");
		//     setBtnTelephone(true);
		//     CustomerService.findByTelephone(values)
		//         .then(response => {
		//             setBtnTelephone(false);
		//             setTelephone(values.telephone);
		//             setRegisterState(1);
		//         }).catch(error => {
		//             console.log(error);
		//         })
		if (registerState === 2) {
			if (bank == 0) {
				Swal.fire(t("SwalNotification"), t("RegisterRequiredBank"), "warning");
				return;
			}
			if (!checkPassword(values.password)) {
				Swal.fire(t("SwalNotification"), t("RegisterRequiredPassword"));
				return;
			}
			setRegisterState(3);
		} else if (registerState === 3) {
			// if (!file) {
			// 	Swal.fire(t("SwalNotification"), t("RegisterRequiredSlip"), "warning");
			// 	return;
			// }
			setBtnRegister(true);
			const formData = new FormData();
			formData.append("file", file);
			formData.append("brand_id", parseInt(brand.id));
			formData.append("brand_game_id", parseInt(brandGameId));
			formData.append("bank_id", parseInt(bank));
			formData.append("game_id", parseInt(gameId));
			formData.append("from_type_id", parseInt(values.from_type_id));
			formData.append(
				"brand_promotion_id",
				parseInt(values.brand_promotion_id)
			);
			formData.append("name", values.name);
			formData.append("telephone", telephone);
			formData.append("line_id", values.line_id);
			formData.append("bank_account", values.bank_account);
			formData.append("password", values.password);
			formData.append("from_type_remark", values.from_type_remark);
			CustomerService.create(formData)
				.then((response) => {
					setBtnRegister(false);
					setRegisterState(4);
					setCustomerResponse(response.data.customer);
					socket.emit("create-customer", brand.id);
				})
				.catch((error) => {
					setBtnRegister(false);
					Swal.fire(error.data, "", "error");
				});
		}
	};

	return (
		<React.Fragment>
			<div className="container-auth mr-1 ml-1">
				<div className="card card-default card-login">
					<div className="card-body">
						<form onSubmit={handleSubmit(handleRegister)} method="post">
							{(() => {
								if (registerState === 0) {
									return (
										<RegisterTelephone
											brandCode={params.brand}
											setRegisterState={setRegisterState}
											setTelephone={setTelephone}
											telephone={telephone}
											setGameRegistered={setGameRegistered}
										/>
									);
								} else if (registerState === 1) {
									return (
										<RegisterGame
											brandCode={params.brand}
											selectGame={selectGame}
											setRegisterState={setRegisterState}
											gameRegistered={gameRegistered}
										/>
									);
								} else if (registerState === 2) {
									return (
										<RegisterInformation
											register={register}
											errors={errors}
											brandCode={params.brand}
											setRegisterState={setRegisterState}
											gameId={gameId}
											selectBank={selectBank}
											bank={bank}
											telephone={telephone}
										/>
									);
								} else if (registerState === 3) {
									return (
										<RegisterTransfer
											register={register}
											errors={errors}
											setRegisterState={setRegisterState}
											brandCode={params.brand}
											gameId={gameId}
											selectFile={selectFile}
											btnRegister={btnRegister}
										/>
									);
								} else if (registerState === 4) {
									return (
										<RegisterSuccess customerResponse={customerResponse} />
									);
								}
							})()}
						</form>
						<div className="row justify-content-between mt-3">
							<div className="col-4">
								<a
									onClick={() => i18n.changeLanguage("th")}
									style={{ fontSize: "30px", cursor: "pointer" }}
								>
									<img src={th} width={20} alt="" />
								</a>
								&nbsp;
								<a
									onClick={() => i18n.changeLanguage("laos")}
									style={{ fontSize: "30px", cursor: "pointer" }}
								>
									<img src={laos} width={20} alt="" />
								</a>
							</div>
							<div className="col-8">
								<p className="text-right mt-3">
									{t("RegisterAlreadyId")}{" "}
									<Link to={`/${params.brand}/login`}>{t("Click")}</Link>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="container-auth">
				<div className="pull-right">
					<button className="btn btn-primary btn-sm mt-2">
						<i className="fab fa-facebook" />
					</button>
					&nbsp;
					<button className="btn btn-success btn-sm mt-2">
						<i className="fab fa-line" />
					</button>
					&nbsp;
					<button className="btn btn-success btn-sm mt-2">
						<i className="fab fa-whatsapp text-white"></i>
					</button>
					&nbsp;
					<button className="btn btn-info btn-sm mt-2">
						<i class="fab fa-internet-explorer"></i>
					</button>
				</div>
			</div>
		</React.Fragment>
	);
}
