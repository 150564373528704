import axios from "axios";
import config from "../config";

const API_URL = config.apiUrl() + "/api/customer";

class ExampleService {

    getScore = () => {
        return new Promise((resolve, reject) => {
            return axios.get(API_URL + "/dashboard/score").then(response => {
                return resolve(response);
            }).catch(error => {
                return reject(error);
            })
        });
    }

    receiveScore = (score) => {
        return new Promise((resolve, reject) => {
            return axios.post(API_URL + "/dashboard/score", {
                score: score,
            }).then(response => {
                return resolve(response);
            }).catch(error => {
                return reject(error);
            })
        });
    }

    create = (values) => {
        return new Promise((resolve, reject) => {
            return axios.post(API_URL + "/register", values).then(response => {
                return resolve(response);
            }).catch(error => {
                return reject(error);
            })
        });
    }

    findByTelephone = (values) => {
        return new Promise((resolve, reject) => {
            return axios.post(API_URL + "/find-by-telephone", values).then(response => {
                return resolve(response);
            }).catch(error => {
                return reject(error);
            })
        });
    }

    withdraw = (values) => {
        return new Promise((resolve, reject) => {
            return axios.post(API_URL + "/withdraw/store", values).then(response => {
                return resolve(response);
            }).catch(error => {
                return reject(error);
            })
        });
    }
}

export default new ExampleService()