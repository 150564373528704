export default function InputHook({
	errors,
	register,
	type,
	errorLabel,
	field,
	validates,
	defaultValue,
	onChangeHandler,
	placeholder,
	disabled = false,
}) {
	return (
		<div>
			{onChangeHandler ? (
				<input
					type={type}
					className={
						errors
							? "form-control is-invalid form-control"
							: "form-control invalid"
					}
					name={field}
					{...register(field, validates)}
					placeholder={placeholder}
					defaultValue={defaultValue}
					disabled={disabled}
					onChange={onChangeHandler}
				/>
			) : (
				<input
					type={type}
					className={
						errors
							? "form-control is-invalid form-control"
							: "form-control invalid"
					}
					name={field}
					{...register(field, validates)}
					placeholder={placeholder}
					defaultValue={defaultValue}
					disabled={disabled}
				/>
			)}
			<span className="invalid-feedback">
				{errors && errors.type === "required" && (
					<span role="alert">{errorLabel.requiredLabel}</span>
				)}
				{errors && errors.type === "minLength" && (
					<span role="alert">{errorLabel.minLengthLabel}</span>
				)}
				{errors && errors.type === "validate" && (
					<span role="alert">{errorLabel.validateLabel}</span>
				)}
				{errors && errors.type === "maxLength" && (
					<span role="alert">{errorLabel.maxLengthLabel}</span>
				)}
			</span>
		</div>
	);
}
