import React from 'react';
import { Routes, Route, useParams, Outlet } from 'react-router-dom';
import Authenticate from './Layouts/Authenticate';
import Dashboard from './Layouts/Dashboard';
import Brand from './Layouts/Brand';
import Deposit from './Pages/Deposit';
import Promotion from './Pages/Promotion';
import Withdraw from './Pages/Withdraw';
import Login from './Pages/Login';
import Register from './Pages/Register';
import './App.css';
import NotFound from './Pages/NotFound';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Home from './Pages/Home';
import { History } from './Pages/History';
import { Profile } from './Pages/Profile';

function App() {
    return (
        <React.Fragment>
            <Routes>
                <Route path="/:brand" element={<Brand />}>
                    <Route element={<Dashboard />}>
                        <Route end path="" element={<Home />} />
                        <Route end path="deposit" element={<Deposit />} />
                        <Route end path="withdraw" element={<Withdraw />} />
                        <Route end path="promotion" element={<Promotion />} />
                        <Route end path="history" element={<History />} />
                        <Route end path="profile" element={<Profile />} />
                    </Route>
                    <Route element={<Authenticate />} >
                        <Route end path="login" element={<Login />} />
                        <Route end path="register" element={<Register />} />
                    </Route>
                </Route>
                <Route path='*' element={<NotFound />} />
            </Routes>
            <ToastContainer />
        </React.Fragment>
    );
}

export default App;
