import BrandService from '../Services/BrandService';
import { ADD_BRAND, REMOVE_BRAND } from './type'

export const addBrand = (brand_code) => (dispatch) => {
    return BrandService.findByCode(brand_code)
        .then(response => {
            dispatch({
                type: ADD_BRAND,
                payload: response.data.brand,
            });
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        });
}

export const removeBrand = () => (dispatch) => {
    dispatch({
        type: REMOVE_BRAND,
        payload: "",
    });
}