import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useOutlet, useParams } from 'react-router-dom';
import second, { addBrand, removeBrand } from '../Actions/brand';

export default function Brand() {
    const [loading, setLoading] = useState(true);
    const outlet = useOutlet();
    const params = useParams();
    const dispatch = useDispatch();
    const { brand } = useSelector((state) => state.brand);
    const { user } = useSelector((state) => state.auth);

    const getBrand = async () => {
        await dispatch(addBrand(params.brand))
            .then(response => {
                setLoading(false);
            }).catch(error => {
                // window.location.href = "/";
            });
    }

    useEffect(() => {
        getBrand();
    }, []);

    return (
        <>
            {(loading) ?
                <></>
                :
                <>
                    {outlet}
                </>
            }
        </>
    )
}
