import React, { useEffect, useState } from 'react';
import BrandPromotionService from '../Services/BrandPromotionService';
import Loading from '../Components/Loading';
import { useTranslation } from 'react-i18next';
import config from '../config';


const Promotion = () => {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [promotions, setPromotions] = useState();

    const getPromotions = async () => {
        await BrandPromotionService.get()
            .then(response => {
                setPromotions(response.data.promotions);
            }).catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        (async function () {
            await getPromotions();
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        })();
    }, []);

    return (
        <>
            {(loading)
                ?
                <>
                    <Loading />
                </>
                :
                <>
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="content">
                                <h5 className="text-white"> <i className="fad fa-gift"></i> {t("Promotion")}</h5>
                                <hr />
                                <div className='row'>
                                    {(promotions.filter((promotion) => promotion.status_web === 1 && promotion.type !== 0).map((promotion) => {
                                        return <div className='col-lg-6'>
                                            {(promotion.image !== "")
                                                ?
                                                <>
                                                    <img src={`${config.storageUrl()}/promotions/${promotion.image}`} className='img-fluid' />
                                                </>
                                                :
                                                <>
                                                    <img src="https://via.placeholder.com/1050" className='img-fluid' />
                                                </>
                                            }
                                            <p className='pb-0 text-white'>{promotion.name}</p>
                                        </div>
                                    }))}
                                </div>
                            </div>
                        </div>
                    </div>
                </>

            }
        </>
    );
}

export default Promotion;
