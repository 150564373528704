import React, { useEffect, useState } from "react";
import config from "../../config";
import { useTranslation } from "react-i18next";

export default function RegisterGameDetail({
	game,
	selectGame,
	gameRegistered,
}) {
	const { t } = useTranslation();
	const [registered, setRegistered] = useState();

	useEffect(() => {
		if (gameRegistered) {
			const findRegistered = gameRegistered.filter((gameRegister, value) => {
				return gameRegister.game_id === parseInt(game.game.id);
			});
			if (findRegistered.length > 0) {
				setRegistered(findRegistered[0]);
			}
		}
	}, []);

	return (
		<>
			<div className="col-lg-12">
				<div
					className="d-flex align-items-start text-muted mb-4"
					style={{ position: "relative" }}
				>
					<div className="flex-shrink-0 me-3 mr-3">
						<img
							src={`${config.storageUrl()}/logo-games/${game.game.logo}`}
							width="80"
							className="avatar-sm rounded"
							alt="..."
						/>
					</div>
					<div className="flex-grow-1">
						<h5 className="fs-14">
							{game.game.name} ({game.remark})
						</h5>
						{game.game.description}
						{registered ? (
							<>
								<p className="mb-0">
									{registered.status === 2 ? (
										<span>{t("RegisterGameDetailSpan1")} </span>
									) : (
										<span>{t("RegisterGameDetailSpan2")}</span>
									)}
									<br />
									<i className="fab fa-line text-success"></i> {t("ContactUs")}
								</p>
							</>
						) : (
							<>
								<button
									onClick={() => selectGame(game.game.id, game.id)}
									className="btn btn-primary btn-sm at-bottom"
								>
									{" "}
									<i className="fa fa-hand-pointer mr-1"></i>{" "}
									{t("ChoseThisGame")}
								</button>
							</>
						)}
					</div>
				</div>
				<hr />
			</div>
		</>
	);
}
