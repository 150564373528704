import React from 'react';
import imgLoading from "../Assets/images/loading2.gif";

const Loading = () => {
    return (
        <>
            <div className="loading-page">
                <div className="loading-icon">
                    <img src={imgLoading} alt="" width={200} height={200} />
                </div>
            </div>
        </>
    );
}

export default Loading;
