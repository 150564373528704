import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BankService from '../../Services/BankService';
import ReactSelect from 'react-select'
import InputHook from '../Input/InputHook';
import SelectHook from '../Input/SelectHook';
import FromTypeService from '../../Services/FromTypeService';
import config from '../../config';
import { useTranslation } from 'react-i18next';

const RegisterInformation = ({ setRegisterState, gameId, register, errors, selectBank, bank, telephone }) => {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [game, setGame] = useState();
    const { brand } = useSelector((state) => state.brand);
    const [banks, setBanks] = useState();
    const [bankOptions, setBankOptions] = useState();
    const [fromTypes, setFromTypes] = useState();
    const [passwordType, setPasswordType] = useState("text");

    const fncGame = async () => {
        const brandGame = await brand.brand_games.find((v, k) => {
            return v.game_id === gameId
        });
        await setGame(brandGame.game);
    }

    const fncBankOption = async (banks) => {
        const bankOption = await banks.map((v, k) => {
            return {
                value: v.id,
                label: v.name,
                image: `${config.storageUrl()}/logo-banks/${v.logo}`
            }
        })
        setBankOptions(bankOption);
    }

    const fncBank = async () => {
        await BankService.get()
            .then(response => {
                setBanks(response.data.banks);
                fncBankOption(response.data.banks);
            }).catch(error => {
                console.log(error);
            })
    }

    const fncFromType = async () => {
        await FromTypeService.get()
            .then(respone => {
                setFromTypes(respone.data.from_types);
            }).catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        (async function () {
            await fncGame();
            await fncBank();
            await fncFromType();
            await setLoading(false);
        })();
    }, [])

    return (
        <>
            {(!loading)
                &&
                <>
                    <h1 className="card-title text-center">
                        📝
                    </h1>
                    <div className="card-text">
                        <h4 className="text-center">{t("RegisterInformationH4")}</h4>
                        <div className="row">
                            <div className="col-lg-12 mt-4">
                                <label for="">{t("RegisterInformtaionName")}</label>
                                <InputHook field="name"
                                    name="name"
                                    type="text"
                                    className="form-control"
                                    register={register}
                                    errors={errors.name}
                                    validates={{
                                        required: true
                                    }}
                                    errorLabel={{
                                        requiredLabel: t("RegisterInformtaionNameRequired")
                                    }}
                                />
                            </div>
                            <div className="col-6">
                                <label for="">{t("RegisterInformationTelephone")}</label>
                                <InputHook field="telephone"
                                    name="telephone"
                                    type="text"
                                    className="form-control"
                                    register={register}
                                    errors={errors.telephone}
                                    defaultValue={telephone}
                                    disabled={true}
                                    readonly={true}
                                />
                            </div>
                            <div className="col-6">
                                <label for="">LINE ID</label>
                                <InputHook field="line_id"
                                    name="line_id"
                                    type="text"
                                    className="form-control"
                                    register={register}
                                    errors={errors.line_id}
                                    validates={{
                                        required: true
                                    }}
                                    errorLabel={{
                                        requiredLabel: t("RegisterInformationLineRequired")
                                    }}
                                />
                            </div>
                            <div className="col-lg-12">
                                <label for="">{t("RegisterInformationBank")}</label>
                                <ReactSelect
                                    options={bankOptions}
                                    onChange={(e) => selectBank(e.value)}
                                    formatOptionLabel={bank => (
                                        <div className="bank-option">
                                            <img src={bank.image} alt="country-image" width="25" />
                                            <span>&nbsp;&nbsp;{bank.label}</span>
                                        </div>
                                    )}
                                ></ReactSelect>
                            </div>
                            <div className="col-lg-12">
                                <label for="">{t("RegisterInformationBankAccount")}</label>
                                <InputHook field="bank_account"
                                    name="bank_account"
                                    type="text"
                                    className="form-control"
                                    register={register}
                                    errors={errors.bank_account}
                                    validates={{
                                        required: true
                                    }}
                                    errorLabel={{
                                        requiredLabel: t("RegisterInformationBankAccountRequired")
                                    }}
                                />
                            </div>
                            <div className="col-lg-12">
                                <label for="">{t("RegisterInformationPassword")}</label>
                                <div className="input-group w-100">
                                    <InputHook field="password"
                                        name="password"
                                        type={passwordType}
                                        className="form-control"
                                        register={register}
                                        errors={errors.password}
                                        validates={{
                                            required: true,
                                            minLength: 6,
                                        }}
                                        errorLabel={{
                                            requiredLabel: t("RegisterInformationPasswordRequired"),
                                            minLengthLabel: t("RegisterInformationPasswordLength")
                                        }}
                                    />
                                    <span className="input-group-text" onClick={() => {
                                        if (passwordType === "password") {
                                            setPasswordType("text");
                                        } else {
                                            setPasswordType("password");
                                        }
                                    }
                                    }>
                                        {(passwordType === "text")
                                            ?
                                            <>
                                                <i className="fad fa-eye-slash"></i>
                                            </>
                                            :
                                            <>
                                                <i className="fad fa-eye"></i>
                                            </>
                                        }
                                    </span>
                                    <small className="d-block text-danger">
                                        {t("RegisterInformationPasswordHelpBlock")}
                                    </small>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <label for="">{t("RegisterInformationFromType")}</label>
                                <SelectHook
                                    register={register}
                                    errors={errors.from_type_id}
                                    field="from_type_id"
                                    name="from_type_id"
                                    options={fromTypes}
                                    className="form-control"
                                    validates={{
                                        required: true
                                    }}
                                    errorLabel={{
                                        requiredLabel: ""
                                    }}
                                />
                            </div>
                            <div className="col-lg-12">
                                <label for="">{t("RegisterInformationRemark")}</label>
                                <InputHook field="from_type_remark"
                                    name="from_type_remark"
                                    type="text"
                                    className="form-control"
                                    register={register}
                                    errors={errors.from_type_remark}
                                />
                            </div>
                        </div>
                        <div className="alert alert-dismissible alert-danger mt-2">
                            <h5 className="alert-heading">{t("RegisterInformationMark")} !</h5>
                            <p className="mb-0">
                                {t("RegisterInformationMarkDetail")}
                            </p>
                        </div>
                        <div className="row mt-2">
                            <div className="col-6">
                                <button type="button" className="btn btn-warning btn-block" onClick={() => setRegisterState(1)}>
                                    <i className="fas fa-chevron-left"></i>&nbsp;&nbsp;
                                    {t("RegisterInformationBtnGame")}
                                </button>
                            </div>
                            <div className="col-6">
                                <button type="submit" className="btn btn-primary btn-block" >
                                    {t("RegisterInformationTransfer")}&nbsp;&nbsp;
                                    <i className="fas fa-chevron-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default RegisterInformation;
